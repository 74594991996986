import React from "react";
import { Row, Col, Popover,Select, Modal } from "antd";
import ImageWithFallback from "@/ImageWithFallback/imgLocal";
import SeoFooterContainer from "@/Footer/SeoContainer";
import {translate} from "$ACTIONS/Translate";
import { getSpecificImageFormat } from "../../actions/helper";
import LazyLoad from "react-lazyload";
import LinkHelper from "@/LinkHelper";

const { Option } = Select;

const urlRegex = /^(http|https|\/)/;

const showTelegramQRCood=()=>{
    const QRCood = Modal.info({
        className: "telegram-qrcode-modal",
        title: "",
        centered: true,
        okText: translate("关闭"),
        closable: true,
        icon:null,
        content: (
            <div style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
            }}>
                <img src={`${process.env.BASE_PATH}/img/footer/social/Contact_TG-VN.jpg`} />
            </div>
        ),
        onOk: () => {
            QRCood.destroy()
        },
    })
}

const renderIconListByFooterData = (field, footerData, supportFormat)=> {
    footerData = footerData || {};
    return !Array.isArray(footerData[field]) ? null : footerData[field].map((v,i)=>{
        let attrs = {style:{}};

        if (urlRegex.test(v.desktop_forwarding_url)) {
            attrs.onClick = (function(v) {
                return () => {
                    window.open(
                        v.desktop_forwarding_url,
                        "_blank"
                    );
                }})(v);
        }
        
        if (v.desktop_text && v.desktop_text.toLowerCase().includes("telegram")) {
            attrs.onClick = showTelegramQRCood;
        }

        let deskImg = getSpecificImageFormat(v, 'desktop_image', supportFormat);
        let deskImgHover = getSpecificImageFormat(v, 'desktop_image_hover', supportFormat);

        const dynamicHref = v?.desktop_forwarding_url || undefined;

        return (<Popover
                  key={i}
                  content={v?.desktop_text !== "" ? v.desktop_text : ""}
                  title=""
                  trigger="hover"
                  overlayClassName="footer-popover"
              >
                <LinkHelper 
                    {...(dynamicHref ? { href: dynamicHref } : {})}
                    blockHrefRedirection
                >
                  <li className={"icon-item2"} {...attrs}
                      onMouseEnter={e=>e.target.setAttribute('src', deskImgHover)}
                      onMouseLeave={e=>e.target.setAttribute('src', deskImg)}>
                      <img loading="lazy" src={deskImg} style={{height:30, display:'inline-block'}} />
                      <img src={deskImgHover} style={{position:'fixed', top:-9999}} />
                  </li>
                </LinkHelper>
              </Popover>)
        })
};
export default class Footer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            Menudata: "",
            HlepMenudata: "",
            showWechatQrcode: false,
            language: translate("越南文"),
            languageArray: [
                { name: "China", language: "中文", path: "/cn" },
                { name: "Thailand", language: translate("泰文"), path: "/th" },
                { name: "Vietnam", language: translate("越南文"), path:"/vn"},
            ],
        };
    }
    handleChange=(e) => {
        if(typeof window !== "undefined"){
            const selectedLang = this.state.languageArray.find(lang => lang.path === e);
            this.setState({ language : selectedLang?.name })
            window.location.href = e;
        }
    }
    render() {
        const {language,languageArray} = this.state;
        const { isIndexPage, seoFooterContent, showFooter }= this.props;
        const isLogin = typeof window !== "undefined" && !!localStorage.getItem("access_token") && localStorage.getItem("access_token");
        let sponsorImgsrc0, sponsorImgsrc1;
        const [supportFormat, footerData] = this.props.footerData;
        if (Array.isArray(footerData?.sponsorship)) {
            let len = footerData.sponsorship.length;
            if (len >= 1) { sponsorImgsrc0 = getSpecificImageFormat(footerData.sponsorship[0], 'desktop_image', supportFormat) }
            if (len >= 2) { sponsorImgsrc1 = getSpecificImageFormat(footerData.sponsorship[1], 'desktop_image', supportFormat) }
        }
        return (
            <React.Fragment>
                 {isIndexPage && <LazyLoad height={454} offset={100}>
                    <div className="common-distance-wrap cooperate-wrap">
                        <div className="common-distance clear-padding">
                            <h2 className="title">{translate("赞助")}</h2>
                            <div style={{
                                width: "100%",
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                margin: "0 20px",
                            }}>
                                <Col span={12}>
                                    {/* <div className="cooperate-content" style={{backgroundImage:`url(${sponsorImgsrc0})`}}></div> */}
                                    <LazyLoad height={808} offset={72} once style={{
                                        display: "flex",
                                        justifyContent:"flex-end",
                                    }}>
                                        <ImageWithFallback
                                            src={sponsorImgsrc0}
                                            width={500}
                                            height={300}
                                            alt="app-picture"
                                        />
                                    </LazyLoad>
                                </Col>
                                <Col span={12}>
                                    <LazyLoad height={808} offset={72} once>
                                        <ImageWithFallback
                                            src={sponsorImgsrc1}
                                            width={600}
                                            height={300}
                                            alt="app-picture"
                                        />
                                    </LazyLoad>
                                </Col>
                            </div>
                        </div>
                    </div>
                </LazyLoad>}
                <div className="common-distance-wrap footer-platform-wrap">
                    {showFooter && seoFooterContent && <>
                        {!!seoFooterContent ? <SeoFooterContainer seocontent={seoFooterContent}/> : null}
                      </>
                    }
                    <div className="common-distance">
                        <Row className="footer-platform">
                            <Col span={7}>
                                <h4>{translate("支付方式")}</h4>
                                <ul className="icon-list">
                                    {renderIconListByFooterData('payment_options', footerData, supportFormat)}
                                </ul>
                            </Col>
                            <Col span={6}>
                                <h4>{translate("关注我们")}</h4>
                                <ul className="icon-list">
                                    {renderIconListByFooterData('social_media', footerData, supportFormat)}
                                </ul>
                            </Col>
                            <Col span={4}>
                                <h4>{translate("博彩责任")}</h4>
                                <ul className="icon-list">
                                    {renderIconListByFooterData('regulation', footerData, supportFormat)}
                                </ul>
                            </Col>
                            <Col span={4}>
                                <h4>{translate("信息安全")}</h4>
                                <ul className="icon-list">
                                    {renderIconListByFooterData('info_security', footerData, supportFormat)}
                                </ul>
                            </Col>
                            {!isLogin && <Col span={3}>
                                <Select
                                    size="large"
                                    dropdownClassName="footer-small-drop"
                                    className={`select-language-box ${language}`}
                                    defaultValue={language}
                                    onChange={this.handleChange}
                                    suffixIcon={<img src={`${process.env.BASE_PATH}/img/icon/icon-extand.svg`} alt="extand"/>}
                                >
                                    {languageArray.map((item) => (
                                        <Option
                                            key={item.path}
                                        >   
                                          <LinkHelper href={item.path} blockHrefRedirection>
                                            <img src={`${process.env.BASE_PATH}/img/footer/language/${item.name}.svg`}/>
                                            <span>{item.language}</span>
                                          </LinkHelper>
                                        </Option>
                                    ))}
                                </Select>
                            </Col>}
                        </Row>
                        <div>
                            <h4>{translate("游戏平台")}</h4>
                            <Row gutter={16}>
                                {!Array.isArray(footerData?.gaming_platform) ? null : footerData.gaming_platform.map((v, i) => {
                                    let deskImg = getSpecificImageFormat(v, 'desktop_image', supportFormat);
                                    let deskImgHover = getSpecificImageFormat(v, 'desktop_image_hover', supportFormat);
                                    const dynamicHref = urlRegex.test(v?.desktop_forwarding_url) ? v?.desktop_forwarding_url : undefined;

                                    return (
                                        <Col
                                            span={3}
                                            style={{
                                                width: "140px",
                                                height: "36px",
                                                marginTop: "10px",
                                                display: "flex",
                                                justifyContent: "center",
                                            }}
                                            key={i}
                                        >
                                          <LinkHelper
                                            {...(dynamicHref ? { href: dynamicHref } : {})}
                                            target="_blank"
                                          >
                                            <img loading="lazy"
                                                src={deskImg||'/cn/img/logo.svg'}
                                                onMouseEnter={e=>e.target.setAttribute('src', deskImgHover)}
                                                onMouseLeave={e=>e.target.setAttribute('src', deskImg)}
                                                width={140}
                                                height={36}
                                                alt={v.desktop_text}
                                            />
                                            <img src={deskImgHover} style={{position:'fixed', top:-9999}} />
                                          </LinkHelper>
                                        </Col>
                                    );
                                })}
                            </Row>
                        </div>
                    </div>
                </div>
                <div className="common-distance-wrap footer-copyright-wrap">
                    <div className="common-distance clear-padding">
                        <div className="copyright-content">
                            <div style={{
                                width: "100%",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                            }}>
                                FUN88 thuộc quyền sở hữu và vận hành bởi Omega Sports Solutions N.V. <br/>
                                FUN88 được cấp phép và quản lý bởi Curaçao Gaming Control Board<br/>
                            </div>
                            <p>
                                {translate("FUN88乐天堂 © 版权所有 违者必究")}
                            </p>
                            <div
                            style={{
                                marginTop: "12px",
                                cursor: "pointer"
                            }}
                            onClick={() => {
                                let LicenseUrlPage = "https://cert.gcb.cw/certificate?id=ZXlKcGRpSTZJak5LY0ZSWVNVTlVTbVo1ZG01RWJWRkdXRXRsYTJjOVBTSXNJblpoYkhWbElqb2lXR2RQY25aa1lqQTRaVkUzWjAxRWVGSnNVRmc1WnowOUlpd2liV0ZqSWpvaVpHUTRNV0kwTkRrME56UmpOekF5TldWbU16QTBNV1V4TURNelpUUmlZbUV4T1RNME9EQTRZMk15T1RGaVlUTmpOMkl6TkdZNU9EUTBOVGd6TmpFNE55SXNJblJoWnlJNklpSjk=";
                                window.open(LicenseUrlPage, "_blank");
                            }}
                            >   
                                <img src={`${process.env.BASE_PATH}/img/footer/newCopyRight.png`} alt="curacao"/>
                                <div
                                style={{
                                    marginTop: "8px",
                                }}
                                >Curaçao Gaming Control Board</div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
